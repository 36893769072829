import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import {Pagination} from 'antd';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import IssuerDetail from './IssuerDetail';

function IssuerServicePage(props) {
  const [records, setRecords] = React.useState([]);
  const [actions] = useOutlet('actions');
  const bottomSheetRef = React.useRef();

  const getRecords = React.useCallback(async () => {
    actions.setLoading(true);
    try {
      setRecords(await actions.fetchIssuers());
    } catch (ex) {
      actions.onApiError(ex);
    }
    actions.setLoading(false);
  }, [actions]);

  React.useEffect(() => {
    getRecords();
  }, [getRecords]);

  function showEditDocumentBottomSheet(record) {
    bottomSheetRef.current.open(
      (record && `Edit Issuer`) || `Create Issuer`,
      <IssuerDetail
        key={Math.random()}
        record={record}
        onClose={() => bottomSheetRef.current.close()}
        onUpdate={getRecords}
      />,
    );
  }

  return (
    <Outer>
      <Wrapper>
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar
            title="Issuers"
            subtitle="雙重驗證的用途，例如：忘記密碼, 註冊...，隸屬於 Auth Micro-service"
            onCreateClick={() => {
              showEditDocumentBottomSheet(null);
            }}
            onRefreshClick={getRecords}
          />
          {records.map((record) => (
            <SimpleResourceItem
              key={record._id.$oid}
              onClick={() => showEditDocumentBottomSheet(record)}
              onDelete={null}>
              <div>{record.name}</div>
            </SimpleResourceItem>
          ))}
        </InfoCard>

        {/* <Pagination
        total={total}
        current={filters.offset + 1}
        pageSize={filters.limit}
        showSizeChanger={false}
        showQuickJumper
        showTotal={(total) => '共 ' + total + ' 筆'}
        onChange={(page, pageSize) => {
          filtersChange({
            offset: page - 1,
            limit: pageSize,
          });
        }}
        style={{marginTop: 20}}
      /> */}

        <BottomUpSheet
          contentStyle={{maxWidth: 900}}
          getInstance={(inst) => {
            bottomSheetRef.current = inst;
          }}
        />
      </Wrapper>
    </Outer>
  );
}

const Outer = styled.div`
  padding: 80px 20px 0px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export default IssuerServicePage;
