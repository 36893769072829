import React, {useContext} from 'react';
import * as Ant from 'antd';
import InputField from '../../components/InputField';
import {useOutlet} from 'reconnect.js';

const reserved = ['_id'];
const defaultValues = {
  default_exp: 120,
  type: 'redirect',
  method: 'email',
  subject: 'DEFAULT SUBJECT',
  token_field_name: 'access_token',
};

class ErrIssuer extends Error {}

export default function IssuerDetail(props) {
  const {record, onClose, onUpdate} = props;
  const [values, setValues] = React.useState(
    Object.assign({}, record || defaultValues),
  );
  const [actions] = useOutlet('actions');
  const isEdit = !!props.record;

  const changeValues = (obj) => setValues((prev) => ({...prev, ...obj}));

  const putRecord = async () => {
    actions.setLoading(true);
    try {
      if (!values.name) {
        throw new ErrIssuer('name 是必填');
      }

      await actions.putIssuer(values);

      onUpdate();
      onClose();
    } catch (ex) {
      if (ex instanceof ErrIssuer) {
        Ant.message.warning(ex.message);
      } else {
        actions.onApiError(ex);
      }
    }
    actions.setLoading(false);
  };

  return (
    <div>
      <InputField
        label="name"
        value={values['name']}
        onChange={(e) => changeValues({name: e.target.value})}
        disabled={isEdit}
        row
        placeholder="這是key , 不要重複"
      />

      <InputField
        style={{maxWidth: 450}}
        label="type"
        value={values['type']}
        onChange={(e) => changeValues({type: e.target.value})}
        row
        hint="驗證方式：code, redirect，web 請用 redirect"
      />

      <InputField
        style={{maxWidth: 350}}
        label="method"
        value={values['method']}
        onChange={(e) => changeValues({method: e.target.value})}
        row
        hint="驗證途徑：email, sms"
      />

      <InputField
        style={{maxWidth: 450}}
        type="number"
        label="default_exp(秒)"
        value={values['default_exp']}
        onChange={(e) => changeValues({default_exp: Number(e.target.value)})}
        row
        hint="每次發送 issue 的 token 有效時間"
      />

      <InputField
        style={{maxWidth: 600}}
        label="sender"
        value={values['sender']}
        onChange={(e) => changeValues({sender: e.target.value})}
        row
        hint="method = email 需設定，對應到notification email sender"
      />

      <InputField
        style={{maxWidth: 700}}
        label="template"
        value={values['template']}
        onChange={(e) => changeValues({template: e.target.value})}
        row
        hint="method = email，對應到notification template ，有設定sender 可以不用填"
      />

      <InputField
        label="redirect_url"
        value={values['redirect_url']}
        onChange={(e) => changeValues({redirect_url: e.target.value})}
        hint="type = redirect時，需要設定"
      />

      <InputField
        style={{maxWidth: 600}}
        label="token_field_name"
        value={values['token_field_name']}
        onChange={(e) => changeValues({token_field_name: e.target.value})}
        row
        hint="搭配redirect_url，token參數的名稱"
      />
      <InputField
        label="subject"
        value={values['subject']}
        onChange={(e) => changeValues({subject: e.target.value})}
        hint="method = email， 有指定sender 或 template 可以不用填"
      />

      <Ant.Button type="primary" style={{marginTop: 32}} onClick={putRecord}>
        CONFIRM
      </Ant.Button>
    </div>
  );
}
